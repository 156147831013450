// src/components/ChatWindow.js

import React, { useEffect, useState, useRef } from 'react';
import { db, storage, functions } from '../firebase';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
  getDoc,
} from 'firebase/firestore';
import {
  TextField,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Button,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import './ChatWindow.css';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import CloseIcon from '@mui/icons-material/Close';

const ChatWindow = ({ chat, supportUid }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [ending, setEnding] = useState(false);
  const [error, setError] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [imageToView, setImageToView] = useState(null);
  const [openEndChatDialog, setOpenEndChatDialog] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (chat.userName) {
        setUserInfo({ fullName: chat.userName });
      } else {
        const userDocRef = doc(db, 'users', chat.userUid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserInfo({
            fullName: `${userDoc.data().firstName} ${userDoc.data().lastName}`,
          });
        }
      }
      setLoading(false);
    };

    fetchUserInfo();
  }, [chat.userUid, chat.userName]);

  useEffect(() => {
    const messagesRef = collection(db, 'SupportChats', chat.id, 'messages');
    const q = query(messagesRef, orderBy('timestamp', 'asc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const msgs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(msgs);
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [chat.id]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '' && selectedImages.length === 0) return;

    setSending(true);
    setError('');

    try {
      const messagesRef = collection(db, 'SupportChats', chat.id, 'messages');
      const chatDocRef = doc(db, 'SupportChats', chat.id);

      // Send text message if any
      if (newMessage.trim() !== '') {
        const messageData = {
          senderUid: supportUid,
          receiverUid: chat.userUid,
          timestamp: serverTimestamp(),
          isRead: false,
          messageText: newMessage.trim(),
        };

        await addDoc(messagesRef, messageData);

        await updateDoc(chatDocRef, {
          lastMessage: newMessage.trim(),
          lastTimestamp: serverTimestamp(),
        });

        setNewMessage('');
      }

      // Send each image as a separate message
      if (selectedImages.length > 0) {
        for (const image of selectedImages) {
          const storageRef = ref(
            storage,
            `SupportChatImages/${chat.id}/${image.name}_${Date.now()}`
          );
          const uploadTask = uploadBytesResumable(storageRef, image);

          const imageUrl = await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              null,
              (error) => reject(error),
              () => {
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    resolve(downloadURL);
                  })
                  .catch(reject);
              }
            );
          });

          const imageMessageData = {
            senderUid: supportUid,
            receiverUid: chat.userUid,
            timestamp: serverTimestamp(),
            isRead: false,
            imageURL: imageUrl,
          };

          await addDoc(messagesRef, imageMessageData);

          await updateDoc(chatDocRef, {
            lastMessage: 'Sent an image',
            lastTimestamp: serverTimestamp(),
          });
        }

        setSelectedImages([]);
      }

      setSending(false);
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
      setSending(false);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImages([...e.target.files]);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleImageClick = (imageUrl) => {
    setImageToView(imageUrl);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setImageToView(null);
  };

  const handleEndChatConfirm = async () => {
    setEnding(true);
    setError('');

    try {
      const endChatFunction = httpsCallable(functions, 'endSupportChat');
      const response = await endChatFunction({ chatId: chat.id, supportUid });

      if (response.data.success) {
        // Chat ended successfully
        setOpenEndChatDialog(false);
      } else {
        throw new Error('Failed to end chat.');
      }
    } catch (error) {
      console.error('Error ending chat:', error);
      setError('Failed to end chat. Please try again.');
      setEnding(false);
    }
  };

  const handleRemoveSelectedImage = (index) => {
    setSelectedImages(selectedImages.filter((_, i) => i !== index));
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress sx={{ color: '#ea191c' }} />
      </Box>
    );
  }

  return (
    <div className="support-chat-window">
      <div className="chat-header">
        <div className="user-info">
          <Typography variant="h6">
            {userInfo ? userInfo.fullName : chat.userUid}
          </Typography>
        </div>
        <Button
          onClick={() => setOpenEndChatDialog(true)}
          disabled={ending}
          sx={{
            backgroundColor: '#ea191c',
            color: '#faeacc',
            '&:hover': {
              backgroundColor: '#897f76',
            },
          }}
        >
          {ending ? 'Ending...' : 'End Chat'}
        </Button>
      </div>
      <div className="chat-messages">
        {messages.map((msg) => (
          <div
            key={msg.id}
            className={`message-row ${
              msg.senderUid === supportUid ? 'sent' : 'received'
            }`}
          >
            <div
              className={`message-bubble ${
                msg.senderUid === supportUid ? 'sent' : 'received'
              }`}
            >
              {msg.imageURL && (
                <img
                  src={msg.imageURL}
                  alt="sent"
                  onClick={() => handleImageClick(msg.imageURL)}
                  style={{ maxWidth: '150px', cursor: 'pointer' }}
                />
              )}
              {msg.messageText && <p>{msg.messageText}</p>}
              <span className="timestamp">
                {msg.timestamp
                  ?.toDate()
                  .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <IconButton
          onClick={() => fileInputRef.current && fileInputRef.current.click()}
        >
          <AttachFileIcon />
        </IconButton>
        <TextField
          placeholder="Type a message"
          multiline
          maxRows={4}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          fullWidth
          variant="outlined"
          sx={{
            borderRadius: '20px',
            backgroundColor: '#fff',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#ea191c',
              },
            },
          }}
        />
        <IconButton
          onClick={handleSendMessage}
          disabled={sending}
          color="primary"
          sx={{
            color: '#313831',
          }}
        >
          {sending ? <CircularProgress size={24} /> : <SendIcon />}
        </IconButton>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
          multiple
        />
      </div>

      {/* Image Previews Before Sending */}
      {selectedImages.length > 0 && (
        <Box
          sx={{
            mt: 1,
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            backgroundColor: '#f1f1f1',
            padding: '8px',
            borderRadius: '8px',
          }}
        >
          {selectedImages.map((image, index) => (
            <Box
              key={index}
              sx={{ position: 'relative', marginRight: '8px', marginBottom: '8px' }}
            >
              <img
                src={URL.createObjectURL(image)}
                alt={`Selected ${index}`}
                style={{ maxHeight: '100px' }}
              />
              <IconButton
                size="small"
                onClick={() => handleRemoveSelectedImage(index)}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      {error && <Typography color="error">{error}</Typography>}

      {/* Image Viewer Dialog */}
      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        maxWidth="lg"
      >
        <DialogContent>
          <img
            src={imageToView}
            alt="Full View"
            style={{ width: '100%', height: 'auto' }}
          />
        </DialogContent>
      </Dialog>

      {/* End Chat Confirmation Dialog */}
      <Dialog
        open={openEndChatDialog}
        onClose={() => setOpenEndChatDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            padding: '16px',
            backgroundColor: '#F3EEE0',
            color: '#000000',
            minWidth: '300px',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          Confirm End Chat
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Are you sure you want to end this chat?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={() => setOpenEndChatDialog(false)}
            sx={{
              backgroundColor: '#ccc',
              color: '#000000',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#bbb',
              },
              mr: 2,
              px: 3,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEndChatConfirm}
            disabled={ending}
            sx={{
              backgroundColor: '#ea191c',
              color: '#ffffff',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#c71518',
              },
              px: 3,
            }}
          >
            {ending ? 'Ending...' : 'End Chat'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChatWindow;
