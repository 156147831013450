// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { SupportChatProvider } from './contexts/SupportChatContext';

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <SupportChatProvider>
            <Router>
                <Routes>
                    {!user && (
                        <>
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="*" element={<Login />} />
                        </>
                    )}
                    {user && (
                        <>
                            <Route path="/dashboard" element={<Dashboard user={user} />} />
                            <Route path="*" element={<Dashboard user={user} />} />
                        </>
                    )}
                </Routes>
            </Router>
        </SupportChatProvider>
    );
}

export default App;
