// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // For authentication
import { getFirestore } from "firebase/firestore"; // For Firestore database
import { getStorage } from "firebase/storage"; // For Firebase Storage (if needed)
import { getFunctions } from "firebase/functions"; // For Firebase Cloud Functions

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDYMN2hDKaNBIahudqOhuBChc6HijUkzNg",
  authDomain: "dashdrop-4506f.firebaseapp.com",
  projectId: "dashdrop-4506f",
  storageBucket: "dashdrop-4506f.appspot.com",
  messagingSenderId: "104183650492",
  appId: "1:104183650492:web:1c5cda63022a5d91fb58b9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const auth = getAuth(app); // Authentication
export const db = getFirestore(app); // Firestore Database
export const storage = getStorage(app); // Firebase Storage (if needed)
export const functions = getFunctions(app); // Firebase Cloud Functions

// Export the app if you need to access it elsewhere
export default app;
