// src/components/AvailabilityToggle.js

import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  Box,
  Button,
  Typography,
  Avatar,
  Badge,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { signOut } from 'firebase/auth';

const StyledBadge = styled(Badge)(({ theme, ownerState }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: ownerState.isAvailable ? '#44b700' : '#f44336',
    color: ownerState.isAvailable ? '#44b700' : '#f44336',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: '15px',
    height: '20px',
    borderRadius: '50%',
  },
}));

const AvailabilityToggle = () => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [openSignOutDialog, setOpenSignOutDialog] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'supportUsers', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setIsAvailable(userDoc.data().isAvailable);
          setFirstName(userDoc.data().firstName || 'Support');
          setLastName(userDoc.data().lastName || 'Agent');
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  const handleToggle = async () => {
    const newStatus = !isAvailable;
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'supportUsers', user.uid);
      await updateDoc(userDocRef, {
        isAvailable: newStatus,
      });
      setIsAvailable(newStatus);
    }
  };

  const handleSignOutConfirm = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'supportUsers', user.uid);
      await updateDoc(userDocRef, {
        isAvailable: false,
      });
      await signOut(auth);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const avatarBackgroundColor = '#313831';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {/* User Avatar as Toggle */}
      <StyledBadge
        overlap="circular"
        badgeContent=" "
        ownerState={{ isAvailable }}
        onClick={handleToggle}
        sx={{ cursor: 'pointer' }}
      >
        <Avatar
          sx={{
            bgcolor: avatarBackgroundColor,
            width: 64,
            height: 64,
            mb: 2,
            fontSize: '1.5rem',
            cursor: 'pointer',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.1)',
            },
          }}
        >
          {`${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`}
        </Avatar>
      </StyledBadge>

      {/* Status Indicator */}
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="body1">
          {isAvailable ? 'Available' : 'Unavailable'}
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      {/* Sign Out Button */}
      <Button
        variant="contained"
        onClick={() => setOpenSignOutDialog(true)}
        sx={{
          width: '120%',
          backgroundColor: '#ea191c',
          color: '#ffffff',
          borderRadius: '18px',
          padding: '8px 0',
          textTransform: 'none',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#c71518',
          },
        }}
      >
        Sign Out
      </Button>

      {/* Sign Out Confirmation Dialog */}
      <Dialog
        open={openSignOutDialog}
        onClose={() => setOpenSignOutDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            padding: '16px',
            backgroundColor: '#F3EEE0',
            color: '#000000',
            minWidth: '300px',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          Confirm Sign Out
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Are you sure you want to sign out?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={() => setOpenSignOutDialog(false)}
            sx={{
              backgroundColor: '#ccc',
              color: '#000000',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#bbb',
              },
              mr: 2,
              px: 3,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSignOutConfirm}
            sx={{
              backgroundColor: '#ea191c',
              color: '#ffffff',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#c71518',
              },
              px: 3,
            }}
          >
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AvailabilityToggle;
