// src/components/Dashboard.js

import React, { useEffect, useState, useContext } from 'react';
import { db } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import ChatWindow from './ChatWindow';
import AvailabilityToggle from './AvailabilityToggle';
import QueueList from './QueueList';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    Avatar,
    ListItemAvatar,
    CssBaseline,
    Divider,
} from '@mui/material';
import { SupportChatContext } from '../contexts/SupportChatContext';
import './Dashboard.css';

const Dashboard = ({ user }) => {
    const [assignedChats, setAssignedChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const { hasUnreadMessages } = useContext(SupportChatContext);

    useEffect(() => {
        const fetchAssignedChats = () => {
            const supportChatsRef = collection(db, 'SupportChats');
            const q = query(
                supportChatsRef,
                where('supportUid', '==', user.uid),
                where('isEnded', '==', false) // Fetch only active chats
            );

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const chats = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAssignedChats(chats);

                // Automatically select the first chat if none is selected
                if (!selectedChat && chats.length > 0) {
                    setSelectedChat(chats[0]);
                } else if (selectedChat) {
                    // Update selectedChat if it was ended
                    const chatExists = chats.some((chat) => chat.id === selectedChat.id);
                    if (!chatExists) {
                        setSelectedChat(null);
                    }
                }
            });

            return () => unsubscribe();
        };

        fetchAssignedChats();
    }, [user.uid, selectedChat]);

    const handleChatSelect = (chat) => {
        setSelectedChat(chat);
    };

    const handleCloseChat = () => {
        setSelectedChat(null);
    };

     // Helper function to determine text color based on background
     const getTextColor = (bgColor) => {
        // Simple algorithm to determine if background is light or dark
        const color = bgColor.substring(1); // Remove '#'
        const rgb = parseInt(color, 16); // Convert to integer
        const r = (rgb >> 16) & 0xff; // Extract red
        const g = (rgb >> 8) & 0xff;  // Extract green
        const b = (rgb >> 0) & 0xff;  // Extract blue
        const luma = (0.299 * r + 0.587 * g + 0.114 * b); // Calculate luma
        return luma < 128 ? '#F3EEE0' : '#000000'; // Return text color
    };

    // Define the background colors
    const middleBgColor = '#212121'; // Dark background for the middle section
    const rightBgColor = '#212121';  // Dark background for the right section

    return (
        <Box sx={{ display: 'flex', height: '100vh', backgroundColor: '#a29c88', padding: '40px' }}>
            <CssBaseline />
    
            {/* Left Sidebar - Very Small */}
            <Box 
                className="drawer-paper" 
                sx={{ 
                    width: '20%', 
                    minWidth: 120, 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'flex-start',
                    marginRight: '20px',  // Add margin to create space between sections
                    backgroundColor: '#faeacc', // Background color for the left section
                    borderRadius: '8px', // Add corner radius to the left section
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', // Add a shadow to float it
                    padding: '20px' // Add padding
                }}
            >
                <AvailabilityToggle />
            </Box>
    
            {/* Middle Section - Queue and Active Chats */}
            <Box 
                className="queue-list" 
                sx={{ 
                    width: '65%', 
                    padding: '10px', 
                    marginRight: '20px', 
                    backgroundColor: middleBgColor,
                    color: getTextColor(middleBgColor), 
                    borderRadius: '8px',     // Add rounded corners
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' // Add a shadow to float it
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Queue
                </Typography>
                <QueueList />
                <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
                <Typography variant="h6" gutterBottom>
                    Active Chats
                </Typography>
                {assignedChats.length === 0 ? (
                    <Typography sx={{ p: 2 }}>No active support chats.</Typography>
                ) : (
                    <List>
                        {assignedChats.map((chat) => (
                            <ListItem
                                button
                                key={chat.id}
                                onClick={() => handleChatSelect(chat)}
                                divider
                                selected={selectedChat?.id === chat.id}
                                sx={{ borderRadius: '8px', m: 1 }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            backgroundColor: '#F3EEE0',  // Set your desired background color
                                            color: '#000000',            // Set your desired text (initials) color
                                        }}
                                    >
                                        {chat.userName ? chat.userName.charAt(0) : chat.userUid.charAt(0)}
                                    </Avatar>
                                </ListItemAvatar>

                                <ListItemText
                                    primary={chat.userName || `User: ${chat.userUid}`}
                                    secondary={`Last message: ${chat.lastMessage}`}
                                    secondaryTypographyProps={{
                                        style: { color: 'rgba(255, 255, 255, 0.6)' },
                                    }}
                                    />

                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
    
            {/* Right Section - Chat Window */}
            <Box 
                className="chat-window" 
                sx={{ 
                    width: '200%', 
                    flexGrow: 1,
                    backgroundColor: rightBgColor, 
                    color: getTextColor(rightBgColor),
                    borderRadius: '8px',     // Add rounded corners
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', // Add a shadow to float it
                    padding: '20px' // Add padding inside the chat window
                }}
            >
                {selectedChat ? (
                    <ChatWindow
                        chat={selectedChat}
                        supportUid={user.uid}
                        onClose={handleCloseChat}
                    />
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <Typography variant="h5" color="F3EEE0">
                            Select a chat to start messaging
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );

};

export default Dashboard;

