// src/components/SignUp.js
import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
} from '@mui/material';

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [error, setError] = useState('');
  
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      // Create user with Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add user to supportUsers collection
      await setDoc(doc(db, "supportUsers", user.uid), {
        uid: user.uid,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        streetAddress: streetAddress,
        city: city,
        state: stateValue,
        zipCode: zipCode,
        isAvailable: false,
        accountType: 2, // support
        createdAt: serverTimestamp(),
        // Add other relevant fields if necessary
      });

      // Redirect to dashboard upon successful sign-up
      navigate('/dashboard');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#F3EEE0',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '40px',
          maxWidth: '600px',
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '12px',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Support Member Sign Up
        </Typography>
        {error && (
          <Typography color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}
        <form onSubmit={handleSignUp}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <TextField
              variant="outlined"
              label="First Name"
              type="text"
              fullWidth
              margin="normal"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <TextField
              variant="outlined"
              label="Last Name"
              type="text"
              fullWidth
              margin="normal"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Box>
          <TextField
            variant="outlined"
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            variant="outlined"
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            variant="outlined"
            label="Phone Number"
            type="tel"
            fullWidth
            margin="normal"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <TextField
            variant="outlined"
            label="Street Address"
            type="text"
            fullWidth
            margin="normal"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
            required
          />
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <TextField
              variant="outlined"
              label="City"
              type="text"
              fullWidth
              margin="normal"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <TextField
              variant="outlined"
              label="State"
              type="text"
              fullWidth
              margin="normal"
              value={stateValue}
              onChange={(e) => setStateValue(e.target.value)}
              required
            />
            <TextField
              variant="outlined"
              label="Zip Code"
              type="text"
              fullWidth
              margin="normal"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              marginTop: '16px',
              backgroundColor: '#313831',
              color: '#F3EEE0',
              '&:hover': {
                backgroundColor: '#212121',
              },
            }}
          >
            Sign Up
          </Button>
        </form>
        <Typography align="center" sx={{ marginTop: '16px' }}>
          Already have an account?{' '}
          <Link to="/login" style={{ color: '#313831', textDecoration: 'none' }}>
            Login
          </Link>
        </Typography>
      </Paper>
    </Box>
  );
};

export default SignUp;
