// src/contexts/SupportChatContext.js

import React, { createContext, useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';

export const SupportChatContext = createContext();

export const SupportChatProvider = ({ children }) => {
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

    useEffect(() => {
        if (!auth.currentUser) {
            return;
        }

        const currentSupportUid = auth.currentUser.uid;
        const chatsRef = collection(db, "SupportChats");
        const q = query(chatsRef, where("supportUid", "==", currentSupportUid));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            let unread = false;
            snapshot.docs.forEach(doc => {
                const chat = doc.data();
                if (chat.hasUnreadMessages) {
                    unread = true;
                }
            });
            setHasUnreadMessages(unread);
        });

        return () => unsubscribe();
    }, []);

    return (
        <SupportChatContext.Provider value={{ hasUnreadMessages }}>
            {children}
        </SupportChatContext.Provider>
    );
};
